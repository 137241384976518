import React from 'react';
import PropTypes from 'prop-types';
import { Title } from './StatisticsSectionTitleStyles';

const SectionTitle = ({ children }) => {
  return <Title>{children}</Title>;
};

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionTitle;
