import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 75px;
  padding: 1rem;
  margin: 1.2rem 0.625rem;
  border-radius: 8px;
  text-align: right;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  color: ${({ theme }) => theme.colors.colorBlueEconomy};
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }

  &:first-child {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0.5rem 0;
  }
`;

export const Data = styled.h2`
  font-weight: 700;
  font-family: ${({ theme }) => theme.fontSizes.titleH4};
  font-size: ${({ theme }) => theme.fontSizes.fontSize9xl4};
  color: ${({ theme }) => theme.colors.colorBlueEconomy};

  @media (max-width: 1024px) {
    font-size: 1.5rem;
  }
`;

export const Title = styled.p`
  margin-left: 0.5rem;
  width: 50%;
  font-size: ${({ theme }) => theme.fontSizes.body1Size};
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.colorNeutralGrayish};

  @media (max-width: 1024px) {
    font-size: 0.875rem;
  }

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.body2Size};
  }
`;
