import { useState } from 'react';
import axios from 'axios';

const useFetchLocations = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchLocations = async (params) => {
    setLoading(true);
    setError(null);
    try {
      const endpoint = process.env.REACT_APP_API_ENDPOINT + 'api/locations';
      const token = process.env.REACT_APP_API_TOKEN;

      const response = await axios.get(endpoint, { 
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
         }, });
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchLocations };
};

export default useFetchLocations;
