import styled from 'styled-components';

export const ChatContainer = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  width: 18.75rem;
  height: ${(props) => (props.isOpen ? '55rem' : 'auto')};
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    width: ${(props) => (props.isOpen ? '60rem' : '18.75rem')};
  }
`;
