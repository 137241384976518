import styled from 'styled-components';

export const ContainerBase = styled.div`
  position: relative;
  width: 100%;
  padding: 0.5rem;
`;

export const SidebarContainer = styled.div`
  position: fixed;
  z-index: 1000;
  left: 1.5rem;
  top: 4rem;
  bottom: 0;

  display: flex;
  flex-direction: column;
  min-height: 300px;
  max-height: 110vh;
  overflow-y: auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  width: calc(100% - 2rem);
  transform: translateX(${(props) => (props.isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (min-width: 48rem) {
    position: absolute;
    width: 20rem;
    transform: translateX(0);
    max-height: calc(130vh - 20rem);
    overflow-y: auto;
  }
`;

export const ToggleButton = styled.button`
  position: fixed;
  z-index: 1001;
  left: 1rem;
  top: 1rem;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  padding: 0.625rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (min-width: 48rem) {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.body1Size};
  font-family: ${({ theme }) => theme.fonts.titleH4};
`;

export const Title = styled.h2``;

export const SelectContainer = styled(ContainerBase)``;

export const Icon = styled.img`
  position: absolute;
  height: 15px;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
`;

export const SelectIcon = styled(Icon)`
  right: 15px;
  left: auto;
  pointer-events: none;
  width: 15px;
`;

export const Select = styled.select`
  border-radius: ${({ theme }) => theme.fontSizes.br3xs};
  border: 1px solid ${({ theme }) => theme.colors.colorNeutralGrayish9f};
  box-sizing: border-box;
  width: 100%;
  height: 41px;
  padding-left: 40px;
  appearance: none;

  color: ${({ theme }) => theme.colors.colorNeutralGrayish};
  font-family: 'Open Sans', 'serif';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
`;

export const CheckboxContainer = styled(ContainerBase)`
  padding: 0.5rem 0;
`;

export const RadiobuttonContainer = styled.div`
  margin-block-start: 0;
  margin-block-end: 0.375rem;
  padding: 0.5rem 0;
`;

export const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.colorNeutralGrayish};
  font-size: ${({ theme }) => theme.fontSizes.body1Size};
  line-height: 18px;
`;

export const CheckboxLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.colorNeutralGrayish};

  &:hover {
    cursor: pointer;
  }
`;

export const CheckboxText = styled.span`
  color: ${({ theme }) => theme.colors.colorNeutralGrayish9f};
  font-size: ${({ theme }) => theme.fontSizes.body1Size};
  line-height: 18px;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  &:checked ~ ${CheckboxText} {
    color: ${({ theme }) => theme.colors.colorNeutralGrayish};
  }
`;

export const RadioLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.colorNeutralGrayish};

  &:hover {
    cursor: pointer;
  }
`;

export const RadioText = styled.span`
  color: ${({ theme }) => theme.colors.colorNeutralGrayish9f};
  font-size: ${({ theme }) => theme.fontSizes.body1Size};
  line-height: 18px;
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
  &:checked ~ ${RadioText} {
    color: ${({ theme }) => theme.colors.colorNeutralGrayish};
  }
`;

export const Separator = styled.hr`
  width: 100%;
  margin: 20px auto;
  border-top: 1px solid ${({ theme }) => theme.colors.colorNeutralGrayishd9};
`;

export const SearchButton = styled.button`
  width: 100%;
  max-width: 16.5625rem;
  height: 3.125rem;
  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.colors.colorBlueEconomy};
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1a39dc;
  }

  padding: 0.5rem 2rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

export const NotificationMessage = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.colorBlueEconomy};
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px auto;
`;
