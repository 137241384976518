import React from 'react';
import { InputContainer, Input, SendButton } from './InputAreaStyles';

const InputArea = ({ input, setInput, handleSend, isBotWriting }) => (
  <InputContainer>
    <Input
      placeholder="Escribe tu mensaje..."
      value={input}
      onChange={(e) => setInput(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          handleSend();
        }
      }}
      disabled={isBotWriting}
    />
    <SendButton onClick={handleSend} isBotWriting={isBotWriting}>
      {' '}
      Enviar
    </SendButton>
  </InputContainer>
);
export default InputArea;
