import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h3`
  margin-bottom: 5px;
`;

export const Subtitle = styled.p`
  margin: 0;
`;

export const TextRight = styled.p`
  margin-left: auto;
  margin-right: 10px;
`;

export const ToggleButton = styled.img`
  cursor: pointer;
`;
