import React, { createContext, useState } from 'react';

const defaultValue = {
  points: [],
  setPoints: () => {
    throw new Error('setPoints function must be overridden by provider');
  },
};

export const MapContext = createContext(defaultValue);

export const MapProvider = ({ children }) => {
  const [points, setPoints] = useState([]);

  return (
    <MapContext.Provider value={{ points, setPoints }}>
      {children}
    </MapContext.Provider>
  );
};
