import { useState } from 'react';

const useChatStream = (setIsBotWriting) => {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');

  const sendMessage = async (userMessage) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: userMessage, user: true },
    ]);
    setIsBotWriting(true);
    try {
      const endpoint = process.env.REACT_APP_CHAT_ENDPOINT;
      const token = process.env.REACT_APP_API_TOKEN;

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ prompt: userMessage }),
      });

      if (!response.ok) {
        console.error('Failed to fetch:', response.statusText);
        throw new Error('Failed to fetch');
      }

      const data = await response.json();

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: data.message, user: false },
      ]);
      setCurrentMessage('');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsBotWriting(false);
    }
  };

  return { messages, currentMessage, sendMessage };
};

export default useChatStream;
