const configs = {
  site1: {
    logo: require('./components/assets/images/logo.png'),
    title: 'Datos de Arica',
    subtitle:
      'Proyecto financiado a través del Fondo de Innovación para Competitividad del Gobierno Regional de Arica y Parinacota y su Consejo Regional enmarcada en la Estratégica Regional de Innovación',
    backgroundImage: require('./components/assets/images/bg-arica.png'),
    mapCenter: { lat: -18.4782534, lng: -70.3125988 },
    hashtag: '#turismoarica',
  },
  site2: {
    logo: require('./components/assets/images/logo.png'),
    title: 'Título del Sitio 2',
    subtitle: 'Subtítulo del Sitio 2',
    backgroundImage: require('./components/assets/images/bg-arica.png'),
    mapCenter: { lat: -18.4782534, lng: -70.3125988 },
  },
};

const currentSite = process.env.REACT_APP_SITE || 'site1';
const config = configs[currentSite];

export default config;
