import React, { useEffect, useRef } from 'react';
import {
  BotMessage,
  MessageContainer,
  MessageTitle,
  UserMessage,
} from './MessageAreaStyles';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const MessageArea = ({ messages, currentMessage }) => {
  const messagesEndRef = useRef(null);
  const messageContainerRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current && messageContainerRef.current) {
      const { current: messageEnd } = messagesEndRef;
      const { current: messageContainer } = messageContainerRef;

      messageContainer.scrollTop =
        messageEnd.offsetTop - messageContainer.offsetTop;
    }
  }, [messages, currentMessage]);

  return (
    <MessageContainer ref={messageContainerRef}>
      {messages.map((message, index) => (
        <div key={index}>
          <MessageTitle>{message.user ? 'Tú' : 'Asistente Virtual'}</MessageTitle>
          {message.user ? (
            <UserMessage>{message.text}</UserMessage>
          ) : (
            <BotMessage><Markdown remarkPlugins={[remarkGfm]}>{message.text}</Markdown></BotMessage>
          )}
        </div>
      ))}
      {currentMessage && (
        <div>
          <MessageTitle>Asistente Virtual</MessageTitle>
          <BotMessage><Markdown remarkPlugins={[remarkGfm]}>{currentMessage}</Markdown></BotMessage>
          
        </div>
      )}
      <div ref={messagesEndRef} />
    </MessageContainer>
  );
};

export default MessageArea;
