import React from 'react';
import PropTypes from 'prop-types';
import {
  HeaderContainer,
  Logo,
  TitleSection,
  Title,
  Subtitle,
} from './HeaderStyles';

const Header = ({ backgroundImage, logo, title, subtitle }) => {
  const scale = window.innerWidth / 1440;

  return (
    <HeaderContainer backgroundImage={backgroundImage}>
      <Logo src={logo} alt="Company Logo" />
      <TitleSection scale={scale}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TitleSection>
    </HeaderContainer>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default Header;
