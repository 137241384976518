import styled from 'styled-components';

export const MapWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 4rem;
  flex-direction: column;
  width: 100%;
`;

export const MapContainer = styled.div`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  height: 120vh; /* Ajusta este valor según tus necesidades */
  box-sizing: border-box;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Opcional: para un ligero efecto de sombra */
`;
