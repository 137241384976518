import React from 'react';
import PropTypes from 'prop-types';
import { CardWrapper, Data, Title } from './StatisticsCardStyles';

const StatisticCard = ({ title, data }) => {
  return (
    <CardWrapper>
      <Data>{data}</Data>
      <Title>{title}</Title>
    </CardWrapper>
  );
};

StatisticCard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default StatisticCard;
