import styled from 'styled-components';

export const LogosContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  width: 50%;
`;

export const Logo = styled.img`
  height: 50px;
  width: auto;
`;

export const Info = styled.p`
  text-align: center;
  margin: 0 auto;
  width: 60%;
  padding: 1.5rem;
`;

export const Separator = styled.hr`
  width: 80%;
  margin: 20px auto;
  border: 0;
  border-top: 1px solid #ccc;
`;
