import styled from 'styled-components';

export const HeaderContainer = styled.header`
  background-image: url(${(props) => props.backgroundImage});
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3% 4%;
  width: 100%;
  height: clamp(200px, 25vw, 400px);
  justify-content: space-between;
`;

export const Logo = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 0;

  @media (min-width: 1440px) {
    width: 330px;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-top: 0;
`;

export const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-size: clamp(24px, 4.5vw, 67px);
  font-weight: 700;
  line-height: clamp(36px, 6.75vw, 101px);
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0;

  @media (min-width: 1440px) {
    font-size: 67px;
    line-height: 101px;
  }
`;

export const Subtitle = styled.h2`
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: clamp(12px, 2vw, 20px);
  font-weight: 400;
  line-height: 1.3rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 100%;
  max-width: 700px;
  margin-bottom: calc(100px - 1.5em);
`;
