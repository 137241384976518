import styled from 'styled-components';

export const UserMessage = styled.div`
  align-self: flex-end;
  background-color: #007bff;
  color: #fff;
  margin: 10px 0 15px 0;
  padding: 10px;
  border-radius: 8px;
  max-width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
`;
export const BotMessage = styled.div`
  background-color: #eee;
  margin: 10px 0 15px 0;
  padding: 10px;
  border-radius: 8px;
  max-width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const MessageContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
`;

export const MessageTitle = styled.div`
  text-align: ${(props) => (props.isUser ? 'right' : 'left')};
  margin: 5px 0 12px 0px;
  font-size: ${({ theme }) => theme.fontSizes.bodySize};
  color: ${({ theme }) => theme.colors.colorNeutralBlue};
  font-weight: 400;

`;
