import React, { useState, useContext, useEffect } from 'react';
import {
  SidebarContainer,
  ToggleButton,
  TitleContainer,
  Title,
  SelectContainer,
  Icon,
  SelectIcon,
  Select,
  CheckboxContainer,
  RadiobuttonContainer,
  Checkbox,
  CheckboxLabel,
  CheckboxText,
  RadioLabel,
  RadioText,
  Radio,
  Separator,
  SearchButton,
  NotificationMessage,
} from './FiltersSidebarStyles';
import collapseIcon from '../../../assets/icons/collapse.svg';
import expandIcon from '../../../assets/icons/expand.svg';
import calendarIcon from '../../../assets/icons/calendar.svg';
import locationIcon from '../../../assets/icons/location.svg';
import { MapContext } from '../../../../context/MapContext';
import useFetchLocations from '../../../../hooks/maps/useFetchLocations';

const FiltersSidebar = () => {
  const { setPoints } = useContext(MapContext);
  const { data, loading, error, fetchLocations } = useFetchLocations();

  const [showYear, setShowYear] = useState(false);
  const [showServiceType, setShowServiceType] = useState(true);
  const [showMapType, setShowMapType] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [serviceType, setServiceType] = useState('');
  const [features, setFeatures] = useState({
    sello_calidad: false,
    sello_sustentabilidad: false,
    protocolo_covid: false,
    buenas_practicas: false,
  });

  useEffect(() => {
    if (data) {
      console.log('Datos recibidos:', data);
      setPoints(data);
    }
  }, [data, setPoints]);

  useEffect(() => {
    if (error) {
      console.error('Error al buscar puntos:', error);
    }
  }, [error]);

  const handleGetLocations = async () => {
    const filteredFeatures = Object.fromEntries(
      Object.entries(features).filter(([, value]) => value),
    );

    const params = {
      type: serviceType,
      limit: 300,
      ...filteredFeatures,
    };

    console.log('Parámetros enviados:', params);
    await fetchLocations(params);
  };

  const handleFeatureChange = (feature) => {
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [feature]: !prevFeatures[feature],
    }));
  };

  return (
    <>
      <ToggleButton onClick={() => setIsOpen(!isOpen)}>
        <img src={isOpen ? expandIcon : collapseIcon} alt="Ver filtros" />
      </ToggleButton>
      <SidebarContainer isOpen={isOpen}>
        
        {showYear && (
          <>
          <TitleContainer onClick={() => setShowYear(!showYear)}>
          <Title>Año</Title>
          <img src={showYear ? collapseIcon : expandIcon} alt="toggle" />
          </TitleContainer>
          <SelectContainer>
            <Icon src={calendarIcon} />
            <SelectIcon src={collapseIcon} />
            <Select>
              {[...Array(25).keys()].map((i) => (
                <option key={i} value={1999 + i}>
                  {1999 + i}
                </option>
              ))}
            </Select>
          </SelectContainer>
          <Separator />
          </>
        )}

        

        
        {showServiceType && (
          <>
            <TitleContainer onClick={() => setShowServiceType(!showServiceType)}>
              <Title>Tipo de servicio</Title>
              <img src={showServiceType ? collapseIcon : expandIcon} alt="toggle" />
            </TitleContainer>
            <RadiobuttonContainer>
            <RadioLabel>
              <Radio
                name="serviceType"
                value="Alojamiento turístico"
                onChange={(e) => setServiceType(e.target.value)}
              />
              <RadioText>Alojamiento Turístico</RadioText>
            </RadioLabel>
            <RadioLabel>
              <Radio
                name="serviceType"
                value="Restaurantes y similares"
                onChange={(e) => setServiceType(e.target.value)}
              />
              <RadioText>Restaurantes y similares</RadioText>
            </RadioLabel>
            <RadioLabel>
              <Radio
                name="serviceType"
                value="Turismo aventura"
                onChange={(e) => setServiceType(e.target.value)}
              />
              <RadioText>Turismo aventura</RadioText>
            </RadioLabel>
          </RadiobuttonContainer>
          <Separator />
          </>
          
        )}

        

        
        {showMapType && (
          <>
          <TitleContainer onClick={() => setShowMapType(!showMapType)}>
          <Title>Tipo de Mapa</Title>
          <img src={showMapType ? collapseIcon : expandIcon} alt="toggle" />
        </TitleContainer>
          <SelectContainer>
            
            <Icon src={locationIcon} />
            <SelectIcon src={collapseIcon} />
            <Select>
              <option value="location">Ubicación</option>
            </Select>
          </SelectContainer>
          <Separator />
          </>
          
        )}

        

        
        {showFeatures && (
          <>
            <TitleContainer onClick={() => setShowFeatures(!showFeatures)}>
          <Title>Características</Title>
          <img src={showFeatures ? collapseIcon : expandIcon} alt="toggle" />
        </TitleContainer>
        <CheckboxContainer>
            <CheckboxLabel>
              <Checkbox onChange={() => handleFeatureChange('sello_calidad')} />
              <CheckboxText>Sello de calidad</CheckboxText>
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox
                onChange={() => handleFeatureChange('sello_sustentabilidad')}
              />
              <CheckboxText>Sello sustentable</CheckboxText>
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox
                onChange={() => handleFeatureChange('protocolo_covid')}
              />
              <CheckboxText>Protocolo COVID</CheckboxText>
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox
                onChange={() => handleFeatureChange('buenas_practicas')}
              />
              <CheckboxText>Buenas prácticas</CheckboxText>
            </CheckboxLabel>
          </CheckboxContainer>x
          </>
          
        )}

        {loading && <NotificationMessage>Cargando...</NotificationMessage>}
        {error && (
          <NotificationMessage>Error: {error.message}</NotificationMessage>
        )}

        <SearchButton onClick={handleGetLocations} disabled={loading}>
          Buscar
        </SearchButton>
      </SidebarContainer>
    </>
  );
};

export default FiltersSidebar;
