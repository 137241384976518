import React from 'react';
import Header from './components/Header/Header';
import StatisticsSection from './components/MainContent/Statistics/StatisticsSection/StatisticsSection';
import { ThemeProvider } from 'styled-components';
import { MapProvider } from './context/MapContext';
import { theme } from './styles/theme';
import { Helmet } from 'react-helmet';
import GlobalStyles from './styles/GlobalStyles';
import config from './config';
import Separator from './components/common/Separator';
import Map from './components/MainContent/MapContainer/Map/Map';
import PreFooter from './components/PreFooter/PreFooter';
import Footer from './components/Footer/Footer';

const App = () => (
  <ThemeProvider theme={theme}>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <GlobalStyles />
    <Header
      backgroundImage={config.backgroundImage}
      logo={config.logo}
      title={config.title}
      subtitle={config.subtitle}
    />
    <StatisticsSection />
    <Separator />
    <MapProvider>
      <Map />
    </MapProvider>
    <PreFooter />
    <Footer />
  </ThemeProvider>
);

export default App;
