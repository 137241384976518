import React, { useState } from 'react';
import ChatHeader from './ChatHeader';
import MessageArea from './MessageArea';
import InputArea from './InputArea';
import { ChatContainer } from './ChatStyles';
import useChatStream from '../../../../hooks/chat/useChatStream';

const Chat = () => {
  const [isChatOpen, setChatOpen] = useState(true);
  const [input, setInput] = useState('');
  const [isBotWriting, setIsBotWriting] = useState(false);
  const { messages, currentMessage, sendMessage } =
    useChatStream(setIsBotWriting);

  const handleToggle = () => {
    setChatOpen(!isChatOpen);
  };

  const handleSend = () => {
    sendMessage(input);
    setInput('');
  };

  return (
    <ChatContainer isOpen={isChatOpen}>
      <ChatHeader onToggle={handleToggle} isChatOpen={isChatOpen} />
      {isChatOpen && (
        <>
          <MessageArea messages={messages} currentMessage={currentMessage} />
          <InputArea
            input={input}
            setInput={setInput}
            handleSend={handleSend}
            isBotWriting={isBotWriting}
          />
        </>
      )}
    </ChatContainer>
  );
};

export default Chat;
