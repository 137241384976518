import React from 'react';
import { Column, FooterContainer, Space } from './FooterStyles';

import logoArica from '../assets/images/logo-arica.png';

const Footer = () => {
  return (
    <FooterContainer>
      <Column>
        <img src={logoArica} alt="Logo" />
      </Column>
      <Column>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id orci
          imperdiet, feugiat velit quis, posuere diam.
        </p>
        <Space />
        <h3>Teléfono</h3>
        <p>+555 8858 5000</p>
      </Column>
      <Column>
        <p>Correo:</p>
        <p>contactotusimoayp.cl</p>
        <Space />
        <h3>Dirección</h3>
        <p>Av. Arica 5552, Arica</p>
      </Column>
      <Column>
        <a href="http://localhost">Inicio</a>
        <a href="http://localhost">Municipalidad</a>
        <a href="http://localhost">Contacto</a>
      </Column>
    </FooterContainer>
  );
};

export default Footer;
