export const theme = {
  fonts: {
    bodyRegular: 'Open Sans',
    titleH4: 'Poppins',
  },
  fontSizes: {
    titleH1Size: '67.34px',
    titleH3Size: '37.9px',
    bodySize: '14px',
    body1Size: '16px',
    body2Size: '12px',
    body3Size: '20px',
    fontSize9xl4: '28.4px',
    fontSize2xl3: '21.3px',
    fontSize48xl3: '67.3px',
    br3xs: '10px',
  },
  colors: {
    colorWhite: '#fff',
    colorEconomyBack: '#f5f5f5',
    colorBlack: '#000000',
    colorNeutralGrayish: '#102a43',
    colorRedEconomy: '#ff453a',
    colorGreenEconomy: '#1dd64c',
    colorBlueEconomy: '#2a49fc',
    colorNeutralGrayish9f: '#9fb3c8',
    colorNeutralGrayishd9: '#d9e2ec',
    colorNeutralBlue: '#334E68',
  },
};
