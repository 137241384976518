import React from 'react';
import { Logo, LogosContainer, Info, Separator } from './PreeFooterStyles';

import logoGR from '../assets/images/logo-gr.png';
import logoCore from '../assets/images/logo-core.png';
import logoED from '../assets/images/logo-ed.png';
import logoUcen from '../assets/images/logo-ucen.png';

const PreFooter = () => {
  return (
    <div>
      <LogosContainer>
        <Logo src={logoGR} />
        <Logo src={logoCore} />
        <Logo src={logoED} />
        <Logo src={logoUcen} />
      </LogosContainer>
      <Info>
        Proyecto financiado a través del Fondo de Innovación para Competitividad
        del Gobierno Regional de Arica y Parinacota y su Consejo Regional,
        enmarcado en la Estrategia Regional de Innovación
      </Info>
      <Separator />
    </div>
  );
};

export default PreFooter;
