import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  padding: 1.8rem 0 8rem 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  max-width: 20%;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Space = styled.div`
  height: 20px;
`;
