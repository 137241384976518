import styled from 'styled-components';

const Separator = styled.hr`
  width: ${({ width }) => width || '85%'};
  margin: ${({ margin }) => margin || '0 auto'};
  border: ${({ border }) =>
    border || `1px solid ${(props) => props.theme.colorNeutralGrayishd9}`};
`;

export default Separator;
