import React from 'react';
import {
  Header,
  ProfileContainer,
  ProfileImage,
  TitleContainer,
  Title,
  Subtitle,
  TextRight,
  ToggleButton,
} from './ChatHeaderStyles';
import config from '../../../../config';
import collapseIcon from '../../../assets/icons/collapse.svg';
import expandIcon from '../../../assets/icons/expand.svg';
import profileIcon from '../../../assets/icons/profile.svg';

const ChatHeader = ({ onToggle, isChatOpen }) => (
  <Header>
    <ProfileContainer>
      <ProfileImage src={profileIcon} />
      <TitleContainer>
        <Title>Asistente Virtual</Title>
        <Subtitle>{config.hashtag}</Subtitle>
      </TitleContainer>
    </ProfileContainer>
    <TextRight>Online</TextRight>
    <ToggleButton
      onClick={onToggle}
      src={isChatOpen ? collapseIcon : expandIcon}
    />
  </Header>
);

export default ChatHeader;
